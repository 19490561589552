import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    headerTop: true,
    headerLinksHidden: false,
    isAACPModalVisible: false,
    hasQuery: false,
    modalPayload: null,
  };

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        headerToBottom: (state, _action) => {
            state.headerTop = false
        },
        headerOnTop: (state, _action) => {
            state.headerTop = true
        },
        toggleHeaderLinks: (state, action) => {
            state.headerLinksHidden = action.payload
        },
        toggleAACPModal: (state, action) => {
            state.isAACPModalVisible = action.payload;
        },
        toggleHasQuery: (state, action) => {
            state.hasQuery = action.payload;
        },
        modalPayload: (state, action) => {
            state.modalPayload = action.payload;
        }
    }
})

export const { actions } = appSlice;

export default appSlice.reducer;
