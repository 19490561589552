import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";
import * as yup from "yup";
import moment from "moment";
import { Link } from "react-router-dom";

import Button from "components/Button";
import CartSummary from "components/CartSummary";
import SubscriptionPlanSelect, {
  getPeriod
} from "components/SubscriptionPlanSelect";
import UpsellProduct from "components/UpsellProduct";
import { CrossSell } from "store/crossSells/types";
import { useGetSubscription } from "utils/customHooks/use-get-subscription";
import { useMediaQuery } from "utils/customHooks/use-media-query";
import { ICommentProps } from "./SignComment";
import { SignUpFormFields } from "./SignUpFormFields";
import { Subscription, SubscriptionDetails } from "store/subscriptions/types";

import becky from "../../../assets/images/beckyMock.jpg";
import stripeImg from "../../../assets/images/common/stripe.svg";
import erin from "../../../assets/images/erinMock.jpg";
import smallLogo from "../../../assets/images/logo-small.png";
import signupMain from "../../../assets/images/signup-main.png";
import wendy from "../../../assets/images/wendyMock.jpg";
import SubscriptionPlanInfo from "components/SubscriptionPlanInfo";

export const countriesList = [
  { value: "US", name: "United States" },
  { value: "CA", name: "Canada" }
];

const COMMENTS_MOCK: ICommentProps[] = [
  {
    author: "BECKY BRONSON",
    avatar: becky,

    description:
      "90% of my time at the grocery store is spent in the produce section—which is a huge shift for me! I love having meals planned for me each week and I LOVE the shopping list! I’m paying more attention to what I eat and planning a lot more."
  },
  {
    author: "ERIN MOTTAYAW",
    avatar: erin,
    description:
      "I used to spend so much time looking through cookbooks and Pinterest to create meal plans— it was exhausting! With Rawk the Year, I plan online and I don't hand-write shopping lists anymore! It's a serious time saver."
  },
  {
    author: "WENDY ROGGE",
    avatar: wendy,
    description:
      "I feel so much better! I’m more fit, energetic AND I’ve lost weight! There’s definitely a difference!!! I love cooking so trying all these new foods has created a whole new world."
  }
];

const personalDataSchema = yup.object({
  firstName: yup
    .string()
    .min(2, "Min first name length is 2")
    .required("Required"),
  lastName: yup.string().required("Required"),
  email: yup
    .string()
    .min(2, "Min last name length is 2")
    .email("Invalid email")
    .required("Required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&#^(){}[\]\\/,."';:|<>+=-]/,
      "Password must contain at least one special character (!, $, @, *, etc)."
    )
    .required("Required"),
  emailConfirmation: yup
    .string()
    .email()
    .oneOf([yup.ref("email"), null], "Emails must match")
    .required("Required"),
  subscriptionIndex: yup.number().required()
});

const paymentDetailsSchema = yup.object({
  cardHolder: yup.string().required(),
  country: yup.string().required(),
  zip: yup.string().required()
});

enum SignUpFormStep {
  PERSONAL_INFO = "Personal Info",
  PAYMENT_DETAILS = "Payment Details"
}

const useYupValidationResolver = validationSchema =>
  React.useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        });

        return {
          values,
          errors: {}
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    },
    [validationSchema]
  );

export function SignUpForm() {
  const { index } = useGetSubscription();

  const [step, setStep] = useState(SignUpFormStep.PERSONAL_INFO);
  const resolver = useYupValidationResolver(
    step === SignUpFormStep.PERSONAL_INFO
      ? personalDataSchema
      : paymentDetailsSchema
  );
  const isMobile = useMediaQuery("(max-width: 993px)");
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [promocode, setPromocode] = useState<string>();
  const [addedCrossSells, setAddedCrossSells] = useState<string[]>([]);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [isPromocodeError, setIsPromocodeError] = useState(false);
  const [isAddPromocodeMode, setIsAddPromocodeMode] = useState(false);

  const subscriptions: Subscription[] = useAppSelector(
    state => state.subscriptions.all
  );

  const methods = useForm({
    resolver,
    mode: "all"
  });

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  const crossSells: CrossSell[] = useAppSelector(state => state.crossSells.all);
  const isLoadingCrosssells: boolean = useAppSelector(
    state => state.crossSells.isLoading
  );

  const subscriptionDetails: SubscriptionDetails = useAppSelector(
    state => state.subscriptions.selectedSubscription
  );

  const { membership, hasPromocodeError } = subscriptionDetails ?? {};
  const { totalAfterTrial, trialDays, period } = membership ?? {};
  const description = subscriptions?.[index]?.description;

  const headerNode = (isSecondary?: boolean) => (
    <div
      className={`signup-wrapper__left__container ${
        isSecondary ? "signup-wrapper__left__container-black" : ""
      }`}
    >
      <div className="signup-wrapper__left__header">
        <img
          src={smallLogo}
          alt={"logo"}
          className="signup-wrapper__left__header-img"
        />
        <h4 className="signup-wrapper__left__header-name">
          Simple Green Smoothies
        </h4>
      </div>
      {/* {isMobile && (
        <Button
          onClick={() => setIsDetailsOpen((prev) => !prev)}
          className="button button-md signup-wrapper__left__header__button"
          type="button"
        >
          {isDetailsOpen ? "Close" : "Details"}
          <img
            className="arrow"
            src={isDetailsOpen ? arrowUp : arrowDown}
            alt="arrow"
          />
        </Button>
      )} */}
    </div>
  );

  const infoNode = (isSecondary?: boolean) => (
    <div
      className={`signup-wrapper__left__info ${
        isSecondary ? "signup-wrapper__left__info-black" : ""
      }`}
    >
      <div className="signup-wrapper__left__top">
        <p className="signup-wrapper__left__info__title">Rawk the Year</p>
        {!!trialDays && (
          <p className="signup-wrapper__left__info-right">{`${trialDays} days free`}</p>
        )}
      </div>
      <p className={`signup-wrapper__left__info__description`}>{description}</p>
    </div>
  );

  const sendSignUp = async (paymentId: string, paymentError?: StripeError) => {
    dispatch({
      type: "SIGN_UP",
      body: {
        firstName: methods.getValues("firstName"),
        lastName: methods.getValues("lastName"),
        email: methods.getValues("email"),
        password: methods.getValues("password"),
        paymentMethodId: paymentId,
        subscriptionId: subscriptions[index]._id,
        promocode,
        crossSellsIds: addedCrossSells
      },
      onError: e => {
        toast(!paymentError ? e : paymentError.message);
        setSignUpLoading(false);
      },
      navigateCheckout: () => {
        window.open("/home", "_self");
      }
    });
  };

  const submitHandler = async () => {
    if (step === SignUpFormStep.PERSONAL_INFO) {
      setStep(SignUpFormStep.PAYMENT_DETAILS);
      return;
    }

    setSignUpLoading(true);
    await elements.submit();

    const {
      paymentMethod,
      error: paymentError
    } = await stripe.createPaymentMethod({
      element: elements.getElement("cardNumber"),
      params: {
        billing_details: {
          name: methods.getValues("cardHolder"),
          email: methods.getValues("email"),
          address: {
            country: methods.getValues("country"),
            postal_code: methods.getValues("zip")
          }
        }
      }
    });

    const paymentId = paymentMethod?.id || "";

    await sendSignUp(paymentId, paymentError);
  };

  const cartSummaryNode = (isMobileFullVersion = true, className = "") => (
    <CartSummary
      className={className}
      promocode={promocode}
      isAddPromocodeMode={isAddPromocodeMode}
      setIsAddPromocodeMode={setIsAddPromocodeMode}
      isPromocodeError={isPromocodeError}
      setIsPromocodeError={setIsPromocodeError}
      onAddPromocode={promocode => setPromocode(promocode)}
      onRemovePromocode={() => setPromocode(undefined)}
      isMobileFullVersion={isMobileFullVersion}
      onRemoveProduct={productId =>
        setAddedCrossSells(prev => prev.filter(id => id !== productId))
      }
    />
  );

  useEffect(() => {
    dispatch({ type: "GET_CROSS_SELLS" });
    dispatch({
      type: "GET_SUBSCRIPTION",
      id: subscriptions[index]?._id,
      promocode,
      crossSellsIds: addedCrossSells
    });
  }, [addedCrossSells, dispatch, index, promocode, subscriptions]);

  useEffect(() => {
    if (hasPromocodeError && promocode) {
      setIsPromocodeError(true);
    }
  }, [hasPromocodeError, promocode]);

  useEffect(() => {
    if (isPromocodeError) {
      setPromocode(undefined);
    }
  }, [isPromocodeError]);

  useEffect(() => {
    methods.setValue("subscriptionIndex", index.toString());
  }, [index, methods]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitHandler)}>
        <div className="signup-wrapper">
          {isMobile && isDetailsOpen && (
            <div className="signup-wrapper__mobile">
              <div className="signup-wrapper__mobile__container">
                {headerNode(true)}
                {infoNode(true)}
                {cartSummaryNode(true, "cart-summary-black")}
              </div>
            </div>
          )}
          <div className="signup-wrapper__left">
            <div className="signup-wrapper__container">
              {headerNode()}
              <img
                src={signupMain}
                alt="signup"
                className="signup-wrapper__left__banner"
              />

              {infoNode()}
              {!!subscriptions?.length && subscriptions.length > 2 ? (
                <SubscriptionPlanSelect />
              ) : (
                <SubscriptionPlanInfo promocode={promocode} />
              )}
              {!isMobile && <div className="divider" />}
              <div className="signup-wrapper__left__summary">
                {cartSummaryNode(false)}
              </div>
            </div>
          </div>
          <div className="signup-wrapper__right">
            <div className="signup-wrapper__container">
              <SignUpFormFields
                comment={COMMENTS_MOCK[0]}
                isPaymentStep={step === SignUpFormStep.PAYMENT_DETAILS}
                onBack={() => setStep(SignUpFormStep.PERSONAL_INFO)}
              />
              {!!crossSells?.length && (
                <div className="signup-wrapper__upsell-products">
                  {crossSells.map(product => (
                    <UpsellProduct
                      key={product.id}
                      product={product}
                      isAdded={!!addedCrossSells?.find(id => product.id)}
                      onAdd={() =>
                        setAddedCrossSells(prev => [...prev, product.id])
                      }
                    />
                  ))}
                </div>
              )}
              {isLoadingCrosssells && !crossSells?.length && (
                <div className="upsell-product" />
              )}
              {step === SignUpFormStep.PERSONAL_INFO && (
                <Button
                  className="sign-up-form__button button-lg"
                  type="submit"
                >
                  ENTER PAYMENT INFO
                </Button>
              )}
              {step === SignUpFormStep.PAYMENT_DETAILS && (
                <Button
                  className="sign-up-form__button button-lg"
                  isLoading={signUpLoading}
                  disabled={signUpLoading}
                  type="submit"
                >
                  ACTIVATE PLAN
                </Button>
              )}
              {step === SignUpFormStep.PAYMENT_DETAILS ? (
                <p className="signup-wrapper__right__terms">
                  By pressing "Activate Plan" you agree to our{" "}
                  <a
                    href="https://simplegreensmoothies.com/terms-of-use#subscription"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="signup-wrapper__right__terms"
                  >
                    Terms of Service
                  </a>
                  .{" "}
                  {`${
                    trialDays ? "After your trial ends, you" : "You"
                  } will be charged $${totalAfterTrial}
                every ${getPeriod(period)} starting ${moment()
                    .add(trialDays || 0, "days")
                    .format("MMMM DD, YYYY")}.`}
                </p>
              ) : null}
              <div className="signup-wrapper__right__login">
                <p className="signup-wrapper__right__login-text">
                  Already have an account?{" "}
                  <Link
                    to={"/login"}
                    className="signup-wrapper__right__login-text"
                  >
                    Sign In
                  </Link>
                </p>
              </div>
              <div className="signup-footer">
                <a
                  href="https://stripe.com"
                  type="_blank"
                  className="footer-left"
                >
                  <span className="footer-left__label">Powered by</span>
                  <img className="stripe" src={stripeImg} alt="logo" />
                </a>
                <div className="footer-right">
                  <a
                    href="https://stripe.com/legal/end-users"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms
                  </a>
                  <a
                    href="https://stripe.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
