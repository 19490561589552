import Input, { IInputProperties } from "forms/Input";
import * as React from "react";
import { Control, Controller } from "react-hook-form";
export interface ISignInputProps extends Omit<IInputProperties, "classNames"> {
  name: string;
  control: Control<any, any>;
}

export function SignInput(props: ISignInputProps) {
  const { name, control, ...restProps } = props;

  const [isShowPassword, setIsShowPassword] = React.useState(false);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div className="sign-input__wrapper">
            <Input
              classNames={{
                root: "sign-up-form__form-root",
                input: `sign-up-form__form-input ${
                  props.error ? "sign-up-form__form-input-error" : ""
                }`,
                label: "sign-up-form__form-label",
                error: "sign-up-form__form-error",
              }}
              {...restProps}
              {...field}
              error={props.error}
              type={isShowPassword ? "text" : restProps.type}
            />
            {restProps.type === "password" && field.value && (
              <button
                className="show-feature"
                onClick={(e) => {
                  e.preventDefault();
                  setIsShowPassword((prev) => !prev);
                }}
              >
                <span className="show-label">
                  {isShowPassword ? "Hide" : "Show"}
                </span>
              </button>
            )}
          </div>
        );
      }}
    />
  );
}
