import { call } from "../core";

export const callGetSubscriptions = () => {
  return call("/subscriptions", "GET", {});
};

export const callGetSubscriptionById = (
  id: string,
  promocode?: string,
  crossSellsIds?: string[]
) => {
  const searchParams = new URLSearchParams();
  if (promocode) {
    searchParams.set("promocode", promocode);
  }

  if (!!crossSellsIds?.length) {
    searchParams.set("crossSellsIds", crossSellsIds.join(","));
  }
  return call(`/subscriptions/${id}?${searchParams}`, "GET", {});
};

export const callGetCheckoutSession = id => {
  return call(`/user/subscription/${id}`, "POST", {});
};

export const callGetUserSubscriptions = () => {
  return call("/user/subscription", "GET", {});
};

export const callEndTrial = () => {
  return call("/stripe/trial-end", "POST", {});
};

export const callCancelSubscription = (id: string, reason, explanation) => {
  return call(`/user/subscription/${id}`, "DELETE", {
    body: {
      reason,
      explanation
    }
  });
};

export const callCreateSubscriptionWithCard = (
  planId: string,
  token: string
) => {
  return call(`/user/subscription/${planId}`, "POST", { body: { token } });
};

export const updateCardInfo = (token: string) => {
  return call("/user/card", "PUT", { body: { token } });
};

export const callCreatePortalSession = (
  path,
  isRestore?: boolean,
  period?: string
) => {
  return call("/stripe/customer-portal", "POST", {
    body: { path, isRestore, period }
  });
};

export const callUpgradeTrial = () => {
  return call("/user/subscription/upgrade-from-trial", "PATCH", {});
};
