import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ISetSelectedSubscription,
  ISetSubscriptions,
  ISetUserSubscriptions,
} from "./types";

const initialState = {
  all: [],
  user: null,
  selectedSubscription: undefined,
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSubscriptions: (state, action: ISetSubscriptions) => {
      state.all = action.payload;
    },
    setUserSubscriptions: (state, action: ISetUserSubscriptions) => {
      state.user = action.payload;
    },
    setSelectedSubscription: (state, action: ISetSelectedSubscription) => {
      state.selectedSubscription = action.payload;
    },
  },
});

export const { actions } = subscriptionsSlice;

export default subscriptionsSlice.reducer;
