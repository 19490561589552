import { call, put, takeLatest } from "redux-saga/effects";
import { actions as SubscriptionsActions } from "../../store/subscriptions/reducer";
import {
  callEndTrial,
  callGetSubscriptionById,
  callGetSubscriptions,
  callGetUserSubscriptions
} from "../../api/subscriptions";

export function* getEnums(action) {
  try {
    yield put({ type: "GET_SUBSCRIPTIONS_REQUEST" });
    const response = yield call(callGetSubscriptions);

    yield put(
      SubscriptionsActions.setSubscriptions(response.data.subscriptions)
    );
    yield put({ type: "GET_SUBSCRIPTIONS_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_SUBSCRIPTIONS_FAILURE",
      error: error.message
    });
  }
}

export function* getSubscription(action) {
  try {
    yield put({ type: "GET_SUBSCRIPTION_REQUEST" });
    const response = yield call(
      callGetSubscriptionById,
      action.id,
      action.promocode,
      action.crossSellsIds
    );

    yield put(SubscriptionsActions.setSelectedSubscription(response.data));
    yield put({ type: "GET_SUBSCRIPTION_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_SUBSCRIPTION_FAILURE",
      error: error.message
    });
  }
}

export function* getUserSubscriptions(action) {
  try {
    // yield put({ type: "GET_USER_SUBSCRIPTIONS_REQUEST" });
    const response = yield call(callGetUserSubscriptions);

    yield put(SubscriptionsActions.setUserSubscriptions(response.data));
    yield put({ type: "GET_USER_SUBSCRIPTIONS_SUCCESS" });
    action.onSuccess();
  } catch (error) {
    yield put({
      type: "GET_USER_SUBSCRIPTIONS_FAILURE",
      error: error.message
    });
  }
}

export function* endTrialPeriod(action) {
  try {
    const response = yield call(callEndTrial);
    yield put(SubscriptionsActions.setUserSubscriptions(response));
    yield put({ type: "END_TRIAL_SUBSCRIPTION_SUCCESS" });
    action.onSuccess();
  } catch (error) {
    action.onError && action.onError();
    yield put({
      type: "END_TRIAL_SUBSCRIPTION_FAILURE",
      error: error.message
    });
  }
}

const authSaga = [
  takeLatest("GET_SUBSCRIPTIONS", getEnums),
  takeLatest("GET_USER_SUBSCRIPTIONS", getUserSubscriptions),
  takeLatest("GET_SUBSCRIPTION", getSubscription),
  takeLatest("END_TRIAL", endTrialPeriod)
];

export default authSaga;
