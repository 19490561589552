const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "development";

const ENVIRONMENT_SETTINGS = {
  base_url: {
    development: "https://master-expansion-rawk-public-api.k8.outsi.de",
    // development: "http://localhost:3210",
    // local: "https://master-expansion-rawk-public-api.k8.outsi.de",
    local: "http://localhost:3210",
    production: "https://api.rawktheyear.com",
    staging: "https://api-staging.rawktheyear.com"
  },
  stripe_pk: {
    development: "pk_test_OiE7j4SJAmNkXeuhsaoXKHhD",
    local: "pk_test_OiE7j4SJAmNkXeuhsaoXKHhD",
    production: "pk_live_AuBosh9pXCMWXYU0cK7KFSjW",
    staging: "pk_test_OiE7j4SJAmNkXeuhsaoXKHhD"
  },
  mp_checkout_link: {
    // maintenance plan checkout link
    development: "https://buy.stripe.com/test_28o6q5dS7bTB3sYbII",
    local: "https://buy.stripe.com/test_28o6q5dS7bTB3sYbII",
    production: "https://buy.stripe.com/5kA9ECbN71BVe52dQR",
    staging: "https://buy.stripe.com/test_28o6q5dS7bTB3sYbII"
  },
  ga_token: {
    development: "test",
    local: "",
    production: "UA-33852285-3",
    staging: "test"
  },
  stripe_checkout_link: {
    development: "https://buy.stripe.com/test_dR6dSxaFVe1J7Je9AC",
    local: "https://buy.stripe.com/test_dR6dSxaFVe1J7Je9AC",
    production: "https://buy.stripe.com/3csdUS4kF0xRe525kq",
    staging: "https://buy.stripe.com/test_dR6dSxaFVe1J7Je9AC"
  },
  ab_test_percent: {
    development: 0,
    local: 0,
    production: 0.5,
    staging: 0
  },
  with_redux_logger: {
    development: true, 
    local: true,
    production: false, 
    staging: false
  }
};

export const isDev = ENVIRONMENT !== "production";

export const BASE_URL = ENVIRONMENT_SETTINGS.base_url[ENVIRONMENT];
export const STRIPE_PK = ENVIRONMENT_SETTINGS.stripe_pk[ENVIRONMENT];
export const GA_TOKEN = ENVIRONMENT_SETTINGS.ga_token[ENVIRONMENT];
export const JOIN_US_LINK =
  "https://www.facebook.com/groups/rawkstarcollective/";
export const COMMUNITY_LINK = "https://rawkstar.circle.so/home";
export const CLEANSE_LINK = "https://sgs.to/rty-cleanse";
export const MP_CHECKOUT_LINK =
  ENVIRONMENT_SETTINGS.mp_checkout_link[ENVIRONMENT];
export const STRIPE_CHECKOUT_LINK =
  ENVIRONMENT_SETTINGS.stripe_checkout_link[ENVIRONMENT];
export const AB_TEST_PERCENT =
  ENVIRONMENT_SETTINGS.ab_test_percent[ENVIRONMENT];
export const WITH_REDUX_LOGGER =
  ENVIRONMENT_SETTINGS.with_redux_logger[ENVIRONMENT];
