import React, { useState, SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Layout from "../../components/LoginLayout";
import PageHeader from "../../components/PageHeader";
import Input from "../Input";
import Button from "../../components/Button";
import check from "../../assets/images/common/check-w.svg";

type Props = {
  loading?: boolean;
  error?: string;
  resetPassword: (
    token: string,
    password: string,
    onSuccess: () => void
  ) => void;
  match: {
    params: {
      token: string;
    };
  };
};

type State = {
  password: string;
  passwordRepeat: string;
  errors: {
    password?: string | null;
    passwordRepeat?: string | null;
  };
  success: boolean;
};

const NewPassword = (props: Props) => {
  const [state, setCurrentState] = useState<State>({
    password: "",
    passwordRepeat: "",
    errors: {},
    success: false
  });

  const setState = (newState: Partial<State>) =>
    setCurrentState(prevState => ({ ...prevState, ...newState }));

  const updateField = (e: SyntheticEvent<HTMLInputElement>, field: string) => {
    setState({ [field]: e.currentTarget.value, errors: {} });
  };

  const validateFields = () => {
    const passwordMinimum = !!state.password && state.password.length > 5;

    if (!passwordMinimum) {
      setState({ errors: { password: "Minimum 6 characters" } });

      return;
    }

    if (state.password) {
      if (state.password.length < 6) {
        setState({ errors: { password: "Minimum 6 characters" } });
        return;
      } else if (!/[A-Z]/.test(state.password)) {
        setState({
          errors: {
            password: "Password must contain at least one uppercase letter"
          }
        });
        return;
      } else if (!/[0-9]/.test(state.password)) {
        setState({
          errors: { password: "Password must contain at least one number" }
        });
        return;
      } else if (!/[@$!%*?&#^(){}[\]\\/,."';:|<>+=-]/.test(state.password)) {
        setState({
          errors: {
            password:
              "Password must contain at least one special character (!, $, @, *, etc)."
          }
        });
        return;
      }
    }

    const passwordMatch = state.password === state.passwordRepeat;

    if (!passwordMatch) {
      setState({ errors: { passwordRepeat: "Passwords do not match" } });

      return;
    }

    return true;
  };

  const onSuccess = () => setState({ success: true });

  const createNewPassword = () => {
    if (validateFields()) {
      props.resetPassword(props.match.params.token, state.password, onSuccess);
    }
  };

  let actionButtonText = props.loading ? "Creating..." : "Create";

  if (state.success) actionButtonText = "Created";

  return (
    <Layout>
      <div className="login-box login-form">
        <PageHeader title="Create New Password" />
        <Input
          disabled={props.loading}
          error={state.errors.password}
          label="Password"
          placeholder="Type Here"
          type="password"
          value={state.password}
          onChange={e => updateField(e, "password")}
        />
        <Input
          disabled={props.loading}
          error={state.errors.passwordRepeat}
          label="Repeat Password"
          placeholder="Type Here"
          type="password"
          value={state.passwordRepeat}
          onChange={e => updateField(e, "passwordRepeat")}
        />
        <Button
          className="action-button"
          disabled={props.loading}
          primary={state.success}
          limited
          onClick={createNewPassword}
        >
          {state.success && <img src={check} alt="Sent" />}
          {actionButtonText}
        </Button>
        <p className="error">{props.error}</p>
        <div className="form-buttons">
          <p>
            Back to <Link to="/login">Log In</Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  loading: state.loading["RESET_PASSWORD"],
  error: state.error["RESET_PASSWORD"]
});

const mapDispatchToProps = dispatch => ({
  resetPassword: (token: string, password: string, onSuccess: () => void) =>
    dispatch({ type: "RESET_PASSWORD", token, password, onSuccess })
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
